@import 'assets/styles/mixins';
@import 'assets/styles/extends';
@import 'assets/styles/palette';
@import 'assets/styles/variables';

.c-Layout__wrap {
  & > * {
    font-family: $font-primary;
    text-transform: uppercase;
    font-weight: normal;
    color: $color-black;
  }

  & .c-FlatCheckout__container {
    padding: 0 32px;

    @include device-desktop {
      padding: 0;
    }
  }

  & .c-Layout__content {
    padding-right: 0;
    padding-bottom: 84px;

    @include device-desktop {
      max-width: 595px;
      margin: 0 auto;
      padding: 0 14px 87px;
    }
  }

  & .c-Column__column {
    flex-basis: 100% !important;
    max-width: 100% !important;

    @include device-desktop {
      flex-basis: 50% !important;
      max-width: 50% !important;
    }

    &.c-Column__small {
      background-color: $color-gray-100;
    }
  }

  & .c-OrderSummary__column {
    padding-top: 0;

    &::after {
      display: none;
    }

    @include device-desktop {
      max-width: 474px;
      margin: 0 auto;
      padding-right: 72px;
    }
  }

  & .c-Layout__columns {
    flex-direction: column;
    padding: 0;

    @include device-desktop {
      flex-direction: row-reverse;
    }
  }

  & .c-Column__column {
    flex: 1 1 50%;
    max-width: 50%;
  }

  // text bigger
  & .c-RadioGroup__container .c-InputBox__inputBox .c-InputBox__labelText,
  & .c-RadioGroup__container .c-InputBox__inputBox .c-InputBox__note,
  & .c-InputBox__radio .c-InputBox__labelInner {
    font-size: 12px;
    line-height: 20px;
    color: $color-black;
    font-weight: 500;
    text-transform: uppercase;
    min-height: 20px;

    @include device-desktop {
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;
      text-transform: uppercase;
      color: $color-black;
    }
  }

  // text normal
  & .c-MessageBox__box {
    font-weight: normal;
    font-size: 10px;
    line-height: 18px;
    letter-spacing: 0.454545px;
    text-transform: none;

    @include device-desktop {
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.5px;
      color: $color-black;
    }
  }

  // buttons, inputs
  & .c-Button__button {
    font-weight: normal;
    font-size: 13px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  /* inputs */
  & .c-TextInput__input,
  & .c-DropDown__select,
  & .c-DropDown__select option {
    font-weight: normal;
    font-size: 13px;
    line-height: 25px;
    letter-spacing: 0.5px;
  }

  // headers small
  & .c-InputBox__labelText,
  & .c-TermsLink__link {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: $color-gray-900;

    @include device-desktop {
      font-weight: normal;
      font-size: 13px;
      line-height: 25px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: $color-gray-900;
    }
  }

  & input:focus,
  select:focus {
    outline: none;
    box-shadow: 0 0 0 1px $color-gray-900 inset;
  }

  // headers normal
  // TODO: change Demi to Medium
  & .c-SectionHeader__title,
  & .c-Summary__title,
  & .c-SummaryRow__total .c-SummaryRow__label,
  & .c-SummaryRow__total .c-SummaryRow__value,
  & .c-GroupTitle__title {
    font-size: 13px;
    line-height: 20px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;

    @include device-desktop {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.8px;
      color: $color-black;
    }
  }

  @include device-desktop {
    & .c-SummaryRow__label,
    & .c-SummaryRow__smaller .c-SummaryRow__label,
    & .c-SummaryRow__value,
    & .c-Price__beforeDiscount,
    & .c-Price__total,
    & .c-Price__discounte,
    & .c-Price__value,
    & .c-ProductsList__list .c-ProductName__link,
    & .c-ProductsList__list .c-ProductName__name,
    & .c-ProductsList__list .c-ProductItem__quantity .c-ProductItem__value,
    & .c-ProductsList__list .c-ProductItem__attributes .c-ProductItem__line {
      font-weight: normal;
      font-size: 13px;
      line-height: 25px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: $color-gray-900;
    }

    & .c-ProductsList__list .c-ProductItem__remove .c-ProductItem__removeButton {
      font-weight: normal;
      font-size: 15px;
      line-height: 24px;
      letter-spacing: 0.46875px;
      text-decoration-line: underline;
      text-transform: uppercase;
      color: $color-black;
    }
  }

  & .c-OrderSummary__header {
    padding-bottom: 0;
  }

  & .c-OrderSection__borderBottom {
    border-bottom-color: $color-gray-500;
  }

  & .c-OrderSection__section {
    background-color: $color-transparent;
    padding: 22px 0;
    margin: 0 32px;

    @include device-desktop {
      margin: 0;
    }

    &[data-block-for='CheckoutProductsList'] {
      padding: 32px 0 40px;

      @include device-desktop {
        padding: 70px 0 9px;
      }
    }

    &[data-block-for='CheckoutSummary'] {
      padding-bottom: 38px;
    }
  }

  & .c-Layout__container > div:first-child:not(.c-Layout__columns) {
    display: none;
  }

  // Products
  & .c-ProductsList__list {
    margin-bottom: 0;
  }

  & .c-ProductItem__item {
    margin: 35px 0;

    @include device-desktop {
      margin: 31px 0;
    }
  }

  & .c-SummaryRow__total {
    margin-top: 38px;
    padding-top: 27px;
    margin-bottom: 15px;
  }

  & .c-Voucher__wrapper {
    & .c-InputBox__label {
      flex-direction: row;
      justify-content: flex-start;
      margin-bottom: 0;
      margin-left: -7px;

      & .c-InputBox__inputToggle {
        border: none !important;
        width: 16px;
        min-width: 16px;
        height: 16px;
        box-shadow: none;
        background-color: transparent !important;

        &::after {
          content: '+';
          background-color: transparent !important;
          top: 0;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: monospace;
          width: 16px;
          height: 16px;
        }

        &:checked {
          background-color: transparent !important;
          border: none !important;
        }

        &:checked::after {
          content: '-';
          background-color: transparent !important;
        }
      }
    }

    & .c-Voucher__input {
      flex: 1 0 auto;
      width: auto;
    }

    & .c-Voucher__input + .c-Button__wrapper {
      flex: 0 0 auto;
      width: auto;
    }

    & .c-Voucher__toggleActive {
      margin-bottom: 22px;
    }
  }

  & .c-ProductsList__loadLessButton {
    padding: 0;
  }

  /* Buttons */
  & .c-Button__wrapper {
    width: 100%;

    & .c-Button__button {
      color: $color-white;
      background-color: $color-gray-900;
      padding: 12px 0 13px;
      width: 100%;
      border-radius: 0;
      box-shadow: none;
    }
  }

  /* Checkbox */
  & .c-InputBox__checkbox:not(.c-InputBox__toggle) .c-InputBox__input {
    border: 1px solid $color-black !important;
    border-radius: 0;
    box-shadow: none;
    height: 20px;
    width: 20px;
    min-width: 20px;

    &:checked {
      background-color: $color-white;
      box-shadow: none;
    }

    &::after {
      background-image: none;
      background-color: $color-black;
      border: 4.5px solid white;
      border-radius: 1px;
    }

    &:checked::after {
      background-image: none;
    }
  }

  /* Radio */
  & .c-InputBox__radio .c-InputBox__labelText::after {
    display: none !important;
  }
  & .c-PaymentOptions__formContent .c-InputBox__inputBox {
    margin-bottom: 19px;
  }

  & .c-InputBox__radio .c-InputBox__input {
    border: 1px solid $color-black !important;
    border-radius: 50%;
    box-shadow: none;
    height: 20px;
    width: 20px;

    &:checked {
      background-color: $color-white;
      box-shadow: none;
    }

    &::after {
      background-image: none;
      background-color: $color-black;
      border: 4.5px solid white;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      margin: 0;
    }

    &:checked::after {
      background-image: none;
    }
  }

  /* Toggle */
  & .c-InputBox__toggle .c-InputBox__input {
    border: 1px solid $color-black !important;
    background-color: $color-white;

    &:checked {
      background-color: $color-black !important;
      box-shadow: none;
    }

    &::after {
      background-image: none;
      background-color: $color-black !important;
      border-radius: 50%;
      top: 4px;
      left: 5px;
      margin: 0;
      height: 10px;
      width: 10px;
    }

    &:checked::after {
      background-image: none;
      background-color: $color-white !important;
    }
  }

  & .c-RadioGroup__container {
    border: none;

    & > div {
      border: none;
      padding: 0 0 25px;
    }

    & .c-InputBox__inputBox {
      padding: 0;
    }

    & .c-InputBox__label {
      margin-bottom: 0;
    }

    & .c-MessageBox__box {
      margin-top: 8px;
      padding: 0;
      margin-left: 32px;
      background-color: $color-transparent;
    }
  }

  & .c-TextInput__input {
    border-radius: 0;
    border: 1px solid $color-gray-900;
    color: $color-gray-900;
    padding: 12px 20px 11px;
  }

  & .c-TextInput__error {
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid #e15c5c;
    box-shadow: none;

    &:focus {
      box-shadow: 0 0 0 1px #e15c5c inset;
    }
  }

  & .c-DropDown__inner::after {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='12' viewBox='0 0 20 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.0002 10.3934L19.2921 1.24224' stroke='black' stroke-linecap='round'/%3E%3Cpath d='M9.99993 10.3934L0.708008 1.24224' stroke='black' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  }

  & .c-DropDown__select {
    border-radius: 0;
    border: 1px solid $color-gray-900;
    padding: 12px 20px 11px !important;
  }

  & .c-DropDown__label,
  & .c-TextInput__label {
    display: none;
  }

  & .c-DropDown__medium {
    width: 100%;
  }

  /* Sections */

  & .c-Summary__title {
    margin-bottom: 24px;
  }

  & .c-InputBox__reverse .c-InputBox__label {
    flex-direction: row;
    justify-content: flex-start;
  }

  & .c-TermsLink__wrapper.c-TermsLink__block {
    display: inline-block;
    padding-left: 4px;
  }

  & .c-InputBox__reverse .c-InputBox__labelInner {
    margin-left: 12px;
  }

  & .c-FlatCheckout__container {
    & .c-Section__section {
      & .c-GroupTitle__title {
        color: $color-black;
        padding: 32px 0 16px;
      }

      &[data-block-for='PersonalInformation'] > .c-GroupTitle__title {
        display: none;
      }

      /* &[data-block-for="ShippingCountry"] {
        order: 1;
      }

      &[data-block-for="PersonalInformation"] {
        order: 2;
      }

      &[data-block-for="BillingAddress"] {
        order: 3;
      }

      &[data-block-for="DeliveryOptions"] {
        order: 4;
      }

      &[data-block-for="PaymentOptions"] {
        order: 5;
      }

      &[data-block-for="PersonalInformation"] {
        order: 2;
      }

      &[data-block-for="PersonalInformation"] {
        order: 2;
      } */
    }
  }
}
