$black: #000;
$white: #ffffff;
$grey: #f5f5f5;
$grey-price: #06154180;
$grey-light: #f4f4f4;
$grey-light-2: #e5e5e5;
$grey-dark: #6b6b6b;
$red: #eb5757;

$color-white: #fff;
$color-black: #000;
$color-transparent: transparent;
$color-gray-50: #f1f1f1;
$color-gray-100: #f3f3f3;
$color-gray-300: #c0c0c0;
$color-gray-500: #979797;
$color-gray-600: #616161;
$color-gray-900: #181818;
$color-red-400: #f05555;
