@import './variables';
@import './mixins';

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

.resetButton {
  margin: 0;
  padding: 0;
  background: $color-transparent;
  width: auto;
  overflow: visible;
  border: none;
  min-width: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  text-align: inherit;
  -webkit-appearance: none;
  vertical-align: middle;
  cursor: pointer;
  background: none;
}
