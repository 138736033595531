@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'reboot';
@import 'fonts';
@import 'extends';
@import 'palette';
@import 'mixins';
@import 'variables';
@import 'lazyload';
@import 'minicart';
@import 'checkout';
@import 'cookieInformation';

// @import url('https://p.typekit.net/p.css?s=1&k=wjz4gvf&ht=tk&f=12783.12784.12785.12786.12787.12788.12789.12790.12791.31623.10419.10421.12093.12095.12096.12098.10415.10416.10417.10418&a=30890529&app=typekit&e=css');

@font-face {
  font-family: 'franklin-gothic-urw';
  src:
    url('/fonts/franklin-gothic-urw-normal-400.woff2') format('woff2'),
    url('/fonts/franklin-gothic-urw-normal-400.woff') format('woff'),
    url('/fonts/franklin-gothic-urw-normal-400.eot') format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'franklin-gothic-urw';
  src:
    url('/fonts/franklin-gothic-urw-normal-500.woff2') format('woff2'),
    url('/fonts/franklin-gothic-urw-normal-500.woff') format('woff'),
    url('/fonts/franklin-gothic-urw-normal-500.eot') format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'franklin-gothic-urw';
  src:
    url('/fonts/franklin-gothic-urw-normal-700.woff2') format('woff2'),
    url('/fonts/franklin-gothic-urw-normal-700.woff') format('woff'),
    url('/fonts/franklin-gothic-urw-normal-700.eot') format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'franklin-gothic-urw';
  src:
    url('/fonts/franklin-gothic-urw-italic-400.woff2') format('woff2'),
    url('/fonts/franklin-gothic-urw-italic-400.woff') format('woff'),
    url('/fonts/franklin-gothic-urw-italic-400.eot') format('opentype');
  font-display: swap;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'franklin-gothic-urw';
  src:
    url('/fonts/franklin-gothic-urw-italic-500.woff2') format('woff2'),
    url('/fonts/franklin-gothic-urw-italic-500.woff') format('woff'),
    url('/fonts/franklin-gothic-urw-italic-500.eot') format('opentype');
  font-display: swap;
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: 'franklin-gothic-urw';
  src:
    url('/fonts/franklin-gothic-urw-italic-700.woff2') format('woff2'),
    url('/fonts/franklin-gothic-urw-italic-700.woff') format('woff'),
    url('/fonts/franklin-gothic-urw-italic-700.eot') format('opentype');
  font-display: swap;
  font-style: italic;
  font-weight: 700;
}

body {
  font-family: $font-primary !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
  margin: 0;
  padding: 0;
  direction: ltr;
  text-align: left;
  overflow-x: hidden;
  background-color: white !important;
  letter-spacing: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  & > #__next {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
}

h1 {
  letter-spacing: 0.15em !important;
  font-weight: 600 !important;
}

h2 {
  letter-spacing: 0.05em !important;
  font-weight: 300 !important;

  @include device-desktop {
    letter-spacing: 0.03em !important;
  }
}

h3 {
  letter-spacing: 0.1em !important;
  font-weight: 600 !important;

  @include device-desktop {
    letter-spacing: 0.2em !important;
  }
}

h4 {
  letter-spacing: 0.1em !important;
  font-weight: 600 !important;

  @include device-desktop {
    letter-spacing: 0.15em !important;
  }
}

h5 {
  letter-spacing: 0.05em !important;
  font-weight: 300 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2em;
}

h1,
h3,
h4 {
  font-family: $font-secondary;
}

h2,
h5 {
  font-family: $font-primary;
}

h3 {
  font-size: 1.5rem;
  line-height: 2rem;
}

img {
  max-width: 100%;
  display: block;
}
a {
  text-decoration: none;
  &:hover,
  &:active {
    text-decoration: none;
  }
}

[class*='lazyload-wrapper'] {
  display: block;
  width: 100%;
  height: 100%;

  & > div {
    display: block;
    width: 100%;
    height: 100%;
  }
}
