@import 'assets/styles/mixins';
@import 'assets/styles/extends';
@import 'assets/styles/palette';
@import 'assets/styles/variables';

[id='CheckoutMultiStepCart'] {
  width: 0;
}

.c-Cart__overlay {
  pointer-events: none;
}

.c-Cart__cart {
  transform: translateX(103%);
  z-index: 10000 !important;
}

.c-Cart__cart > * {
  font-family: $font-primary;
  text-transform: uppercase;
  font-weight: normal;
  color: $color-black;
}

.c-Cart__cart.c-Cart__open {
  transform: translateX(0);
}

// text normal
.c-SummaryRow__label,
.c-SummaryRow__smaller .c-SummaryRow__label,
.c-SummaryRow__value,
.c-Price__beforeDiscount,
.c-Price__total,
.c-Price__discounte,
.c-Price__value,
.c-ProductsList__list .c-ProductName__link,
.c-ProductsList__list .c-ProductName__name,
.c-ProductsList__list .c-ProductItem__quantity .c-ProductItem__value,
.c-ProductsList__list .c-ProductItem__attributes .c-ProductItem__line {
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: $color-gray-900;
  font-weight: 400;
}

// text bigger
.c-ProductsList__list .c-ProductItem__remove .c-ProductItem__removeButton {
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.40625px;
  text-decoration-line: underline;
  text-transform: uppercase;
  font-weight: normal;
}

// buttons
.c-Button__button.c-Button__primary {
  font-weight: normal;
  font-size: 13px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
}

// headers normal
// TODO: change Demi to Medium
.c-Cart__header .c-SectionHeader__title,
.c-Cart__header .c-Button__button .c-Button__inner,
.c-Summary__title,
.c-SummaryRow__total .c-SummaryRow__label,
.c-SummaryRow__row:first-child::before {
  font-size: 15px;
  line-height: 20px;
  font-weight: 700;
}

.c-Cart__header .c-Button__button .c-Button__inner {
  text-transform: uppercase;
  color: black;
}

// headers bigger
.c-SummaryRow__total .c-SummaryRow__value {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.8px;
  font-weight: 700;
}

// Header
.c-Cart__header {
  padding: 36px 37px 15px;
}

// Products
.c-ProductsList__list {
  margin-bottom: 20px;

  & .c-ProductItem__item + .c-ProductItem__item {
    margin-top: 34px;
  }

  & .c-ProductItem__image {
    padding-right: 0;
    margin-right: 20px;
    flex: 0 0 100px;
    max-width: 100px;
  }

  & .c-ProductItem__details {
    margin-top: 6px;
  }

  & .c-ProductItem__left {
    flex: 1 0 50%;
    width: auto;
  }

  & .c-ProductItem__right {
    flex: 1 0 auto;
    width: auto;
  }

  & .c-ProductItem__buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 25px;
    margin-left: 0;

    & .c-ProductItem__quantity {
      height: 16px;
      margin-left: -6px;
    }

    & .c-ProductItem__remove {
      margin-top: 20px;
      margin-left: 0;
    }

    & .c-IconButton__button {
      display: flex;
      height: 16px;
      width: 16px;
    }

    & .c-SvgIcon__icon {
      background: none;
      position: relative;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    & .c-SvgIcon__minus::before {
      content: '-';
    }

    & .c-SvgIcon__plus::before {
      content: '+';
    }
  }

  & .c-ProductItem__attributes {
    display: flex;
    flex-direction: column;
    margin-top: 0;

    & .c-ProductItem__line {
      &:first-child {
        order: 2;

        &::before {
          content: 'Size - ';
        }
      }

      &:last-child {
        order: 1;

        &::before {
          content: 'Color - ';
        }
      }
    }
  }
}

// Summary
.c-SummaryRow__row {
  margin-bottom: 0;
  flex-wrap: wrap;
}

.c-SummaryRow__total {
  margin-bottom: 5px;
  margin-top: 38px;
  border-top-color: $color-gray-500;
}

// Sections
.c-OrderSection__section {
  margin: 0 39px 0 37px;

  &[data-block-for='CartProductsList'] {
    padding-bottom: 14px;
  }

  &[data-block-for='CartProductsList'] {
    padding-top: 10px;
  }

  & .c-Summary__title {
    margin-bottom: 4px;
  }

  &.c-OrderSection__borderBottom {
    border-bottom-color: $color-transparent;
  }

  .c-Cart__footer & {
    border-top: 1px solid $color-gray-500;

    &:last-child {
      border-top: none;
    }
  }
}

// Buy buttons
.c-PaymentLogosBar__wrapper {
  display: none;
}

.c-OrderSection__content {
  & .c-Button__wrapper {
    & .c-Button__button {
      color: $color-white;
      background-color: $color-gray-900;
      padding: 12px 0 13px;
      width: 100%;
      border-radius: 0;
      box-shadow: none;
    }
  }

  & .c-Cart__separator {
    margin: 20px 0;
  }

  & .c-Cart__separator + .c-Button__wrapper {
    & .c-Button__button {
      background-color: $color-transparent;
      color: $color-gray-900;
      border: 2px solid $color-gray-900;
    }
  }
}

// Overflowing cart
.c-Cart__cart.c-Cart__overflowed {
  & .c-Cart__header,
  & .c-Cart__footer {
    box-shadow: none;
  }
}
