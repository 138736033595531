/* stylelint-disable selector-id-pattern */

.coi-banner__wrapper {
  position: relative !important;
  width: 628px !important;
  border-radius: 0 !important;
}

.coi-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 10px;
  margin: 20px;
  background-repeat: no-repeat;
  background-image: url('/images/icons/icon_close.svg');
  background-position: 50% 50%;
  cursor: pointer;
}

.coi-banner-consent-field {
  flex: unset !important;
  border-right: none !important;
  width: 50% !important;
  padding: 10px 0 !important;
  border-bottom: none !important;
}

.coi-checkboxes {
  flex-direction: row !important;
  font-size: 12px !important;
  letter-spacing: 0.2px !important;
  line-height: 18px !important;
}

.coi-checkboxes .checkbox-toggle {
  height: 20px !important;
  width: 45px !important;
  margin-right: 15px !important;
  margin-top: 0 !important;
  border: 1px solid #cecece !important;
  background: transparent !important;
}

.coi-checkboxes .checkbox-toggle::before {
  height: 16px !important;
  width: 16px !important;
  background-color: #cecece !important;
  top: 2px !important;
}

.coi-checkboxes input[type='checkbox']:checked + .checkbox-toggle {
  border: 1px solid #000 !important;
}

.coi-checkboxes input[type='checkbox']:checked + .checkbox-toggle::before {
  background-color: #000 !important;
  left: 25px !important;
}

.coi-banner__summary {
  padding: 80px 60px 30px !important;
  background: #fff !important;
}

.coi-banner__page-footer {
  padding: 0 60px 20px !important;
  position: relative !important;
}

.coi-banner-consent-group {
  padding: 20px 60px 60px !important;
  background: #fff !important;
}

.coi-banner__footer {
  display: none !important;
}

.coi-banner__wrapper a[href]:not([disabled]) {
  color: #000 !important;
}

.coi-checkboxes input[type='checkbox']:disabled + .checkbox-toggle {
  opacity: 1 !important;
}

.coi-button-group {
  justify-content: center !important;
}

.coi-banner__decline,
.coi-banner__lastpage {
  margin: 0 !important;
  border: solid 1px #000 !important;
  background: transparent !important;
  color: #000 !important;
  padding: 0 !important;
  line-height: 24px !important;
  font-weight: 300 !important;
  height: 30px !important;
  max-width: 156px !important;
  margin-right: 20px !important;
  flex: unset !important;
  text-transform: uppercase !important;
  border-radius: 0 !important;
}

.coi-banner__accept,
.coi-banner__update {
  margin: 0 !important;
  border: solid 1px #000 !important;
  background: #000 !important;
  color: #fff !important;
  padding: 0 !important;
  line-height: 24px !important;
  font-weight: 300 !important;
  height: 30px !important;
  max-width: 156px !important;
  margin-right: 20px !important;
  flex: unset !important;
  text-transform: uppercase !important;
  border-radius: 0 !important;
}

.coi-banner__accept.coi-banner__white,
.coi-banner__update.coi-banner__white {
  border: solid 1px #000 !important;
  background: transparent !important;
  color: #000 !important;
}

#coiConsentBannerCategoriesWrapper {
  background: #fff !important;
  border-bottom: none !important;
  padding-bottom: 30px !important;
}

.coi-consent-banner__category-container {
  padding: 0 60px !important;
}

.coi-toggle-group button:focus {
  outline: none !important;
}

.coi-consent-banner__category-container button:focus {
  outline: none !important;
}

.coi-banner__page {
  background: #fff !important;
  font-size: 13px !important;
}

.cookiedeclaration_wrapper {
  padding: 40px 60px !important;
}

.coi-banner__cookiedeclaration {
  padding: 40px 60px 0 !important;
}

.coi-banner__headline {
  display: none !important;
}

.coi-banner__policy {
  display: inline !important;
}

.coi-banner__maintext,
#show_details,
#hide_details {
  font-size: 13px !important;
  letter-spacing: 0.3px !important;
  line-height: 20px !important;
}

.coi-banner__maintext a {
  font-weight: 400 !important;
}

div.coi-banner__maintext {
  line-height: 20px !important;
}

.ci-btn-tab-active h4,
.coi-consent-banner__category-name:hover span {
  color: #000 !important;
}

.coi-consent-banner__category-name span {
  font-size: 12px !important;
  text-transform: uppercase !important;
}

.ci-arrow {
  width: 7px !important;
  height: 7px !important;
  border-width: 0px 2px 2px 0 !important;
  padding: 2px !important;
}

.coi-button-group button {
  width: 100%;
  text-align: center !important;
  font-size: 12px !important;
}

@media only screen and (max-width: 350px) {
  .coi-banner__page,
  #coi-banner-wrapper .coi-button-group button {
    font-size: 11px !important;
  }
}

@media only screen and (max-width: 576px) {
  .coi-close {
    margin: 1.5rem;
  }

  .coi-checkboxes {
    font-size: 11px !important;
    letter-spacing: 0.1px !important;
    line-height: 16.5px !important;
    font-weight: 400 !important;
  }

  .coi-checkboxes .checkbox-toggle::before {
    top: 1px !important;
  }

  #coi-expand {
    display: none !important;
  }

  #coi-banner-wrapper {
    max-height: 70vh !important;
    overflow: auto !important;
    width: 355px !important;
  }

  #coiOverlay {
    padding: 10px !important;
    align-items: center !important;
  }

  .coi-banner__summary {
    padding: 30px 20px 25px !important;
  }

  .coi-banner__page-footer {
    padding: 20px !important;
  }

  .coi-banner__decline,
  .coi-banner__accept {
    margin-right: 10px !important;
  }

  .coi-banner-consent-group {
    padding: 10px 20px !important;
  }

  .coi-banner-consent-field {
    width: 50% !important;
    padding: 8px 0 !important;
  }

  .coi-consent-banner__category-container {
    padding: 0 20px !important;
  }

  .cookiedeclaration_wrapper {
    padding: 30px 20px !important;
  }

  .coi-banner__cookiedeclaration {
    padding: 30px 20px 0 !important;
  }

  .coi-consent-banner__switch-container .coi-checkboxes .checkbox-toggle {
    box-sizing: border-box !important;
    height: 20px !important;
    width: 45px !important;
    transform: scale(1) !important;
  }

  .coi-checkboxes input[type='checkbox']:checked + .checkbox-toggle::before {
    top: 1px !important;
    left: 24px !important;
  }

  .coi-button-group {
    flex-direction: column !important;
    align-items: center !important;
  }

  .coi-button-group button {
    margin-right: 0 !important;
    margin-bottom: 10px !important;
  }
}
