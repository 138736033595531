@mixin scale-link-arrow {
  transition: transform 0.2s ease-out;
  transform: scale(1);

  &:hover {
    transform: scale(1.2);
  }
}

@mixin scale-tile {
  transition: transform 0.2s ease-out;
  transform: scale(1);

  &:hover {
    transform: scale(1.05);
  }
}

@keyframes bag-shake {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, -4px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes product-accordion-slide {
  from {
    opacity: 0;
    transform: translate(0, -10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes block-slide-down {
  from {
    transform: translate(0, -10px);
  }
  to {
    transform: translate(0, 0);
  }
}

@keyframes fadeInImg {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes enter-product-item {
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1);
  }
}

@keyframes menu-fade-in {
  0% {
    opacity: 0;
    padding-top: 6px;
  }
  100% {
    opacity: 1;
    padding-top: 16px;
  }
}

@keyframes reveal-list {
  0% {
    height: 0;
    opacity: 0;
  }
  50% {
    height: auto;
    opacity: 0;
  }
  100% {
    height: auto;
    opacity: 1;
  }
}

@keyframes fade-out-loading {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes header-currency-slide {
  from {
    opacity: 0;
    transform: translate(0, -10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

@keyframes spinnerAnimation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
