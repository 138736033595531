@import 'assets/styles/animations';

:global(.LazyLoad.is-visible img, .LazyLoad.is-visible picture) {
  opacity: 0;
  transition: all 2s ease-in-out;
}

:global(.LazyLoad.is-visible.active img, .LazyLoad.is-visible.active picture) {
  animation: fadeInImg cubic-bezier(0.23, 1, 0.32, 1) 1;
  opacity: 0;
  animation-fill-mode: forwards;
  animation-duration: 0.7s;
  animation-delay: 0.1s;
}
