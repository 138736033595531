@import 'assets/styles/palette';

@mixin device-mobile-large {
  @media screen and (min-width: 641px) {
    @content;
  }
}

@mixin device-tablet {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin device-mobile-and-tablet {
  @media screen and (max-width: 1023px) {
    @content;
  }
}

@mixin device-desktop {
  @media screen and (min-width: 1024px) {
    @content;
  }
}

@mixin device-desktop-large {
  @media screen and (min-width: 1440px) {
    @content;
  }
}

@mixin ie-media {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

@mixin reset-button {
  appearance: none;
  border: none;
  padding: 0;
  margin: 0;
  box-shadow: none;
  background-color: $color-transparent;

  &:focus,
  &:active {
    outline: none;
  }
}

@mixin visually-hidden {
  &:not(:focus):not(:active) {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
}

@mixin text-hidden {
  text-indent: -9999em;
}

@mixin description-break {
  word-break: break-word; /* Samsung browser */
  word-wrap: break-word; /* IE 11 */
  overflow-wrap: anywhere;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
