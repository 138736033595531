@font-face {
  font-family: 'ITC Franklin Gothic';
  src:
    local('ITC Franklin Gothic Demi'),
    local('FranklinGothic-Demi'),
    url('/fonts/FranklinGothic-Demi.woff2') format('woff2'),
    url('/fonts/FranklinGothic-Demi.woff') format('woff'),
    url('/fonts/FranklinGothic-Demi.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
